let clickCount = 0;
let timer: any;

export const useCustomClick = (
  singleClick: Function,
  doubleClick: Function,
  ...args: any[]
) => {
  clickCount++;

  switch (clickCount) {
    case 1:
      timer = setTimeout(() => {
        typeof singleClick === "function" && singleClick(...args);

        clickCount = 0;
      }, 200);
      break;
    case 2:
      clearTimeout(timer);

      if (doubleClick) doubleClick(...args);
      clickCount = 0;
  }
};
